@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.content-body {
  background-position: center;
}

.header {
  background-position: center;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 24px;
}

.header img {
  width: 151px;
  height: auto;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lilita-one-regular {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

.btn-full-screen {
  background: linear-gradient(180deg, #F49908 0%, #FFC105 100%) !important;
  border-radius: 120px;
  box-shadow: 0px 7px 0px 0px #FA8900;
  border: 3px solid #FB9A08;
}

.game-section,
.similar-games {
  background-position: center;
  border-radius: 12px;
  color: white;
  margin-bottom: 16px;
  border-radius: 12px;
  display: flex;
}

.description {
  color: white;
  padding: 32px;
  margin-bottom: 16px;
  display: block;
  background-color: #8db127;
}

.game-title {
  color: #ffffff;
  font-weight: 800;
}

.des-game {
  text-align: left;
}

.about-game {
  text-align: left;
  font-weight: 700;
}

.control {
  border-radius: 22px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url(./assets/images/bg-control-new.png) !important;
  background-repeat: round;
  position: relative;
  background-size: cover;
  z-index: 2;
}

@media (max-width: 767px) {
  .control {
    background-image: url(./assets/images/bg-control-mb.png) !important;
  }
}

@media (min-width: 1024px) {
  .control {
    background-image: url(./assets/images/bg-control-new.png) !important;
  }
}

.content-des {
  background-image: url(./assets/images/background.png);
  background-repeat: round;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .content-des {
    background-image: url(./assets/images/background-mb.png);
  }
}

@media (min-width: 1024px) {
  .content-des {
    background-image: url(./assets/images/background.png);
  }
}

.game-screen {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 8px;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.game-item {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.game-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.game-item:hover img {
  transform: scale(1.1);
}

.game-name {
  background-color: #101010;
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  padding: 12px;
}

.load-more {
  text-align: center;
  margin-top: 26px;
}

.load-more button {
  background-color: #8db127;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  margin-bottom: 40px;
}

.conten-footer {
  background-image: url(./assets/images/bg-footer.png);
  background-position: center;
}

.footer {
  color: white;
  text-align: center;
  padding: 19px 0;
}

ul.numbered-list {
  list-style: none;
  counter-reset: list-counter;
}

ul.numbered-list li {
  counter-increment: list-counter;
  margin-bottom: 8px;
}

ul.numbered-list li::before {
  content: counter(list-counter) ". ";
  font-weight: 400;
  color: #383838;
  margin-right: 8px;
}

.swiper-button-next,
.swiper-button-prev {
  background: none;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0px !important;
}

.swiper-pagination-bullet {
  background-color: #BEC3C3 !important;
  opacity: 100 !important;
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet-active {
  background-color: #009C4C !important;
  width: 12px !important;
  height: 12px !important;
}

.button-next {
  width: 45px;
  height: 74px;
}

.button-prev {
  width: 45px;
  height: 74px;
}

.custom-slide {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container {
  max-width: 100% !important;
}

.bg-playnow {
  background-position: center;
  background-repeat: no-repeat;
}

@keyframes zoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.animate-zoom {
  animation: zoom 1s ease-in-out infinite;
}